<template>
  <div class="gerenciamento_de_pecas">
    <v-container fluid id="element">
   
      <v-card class="mt-2 py-4 px-2" elevation="20" color="primary">
        <v-row no-gutters>
          <v-col class="px-2">
            <v-toolbar-title
              class="ml-2 mt-2"
              style="color: #fff"
              data-intro="Bem-vindo ao DASHBOARD , clique NEXT para continuar"
            >
              DASHBOARD
            </v-toolbar-title>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container fluid>
      <v-card elevation="2" class="mt-4">
        <v-container>
          <v-row
            data-intro="Ou se preferir, selecione o período que deseja. Mas atenção, somente por escolher uma modalidade de pesquisa, ou por mês ou por período "
          >
            <v-col cols="3">
              <v-text-field
                label="Data inicial"
                v-model="dataFiltrar"
                outlined
                dense
                type="date"
              ></v-text-field>
              <br />
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Data inicial"
                v-model="dataFiltrarFim"
                outlined
                dense
                type="date"
                @blur="filtropordata"
              ></v-text-field>
              <br />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
              <v-autocomplete

                outlined
                dense
                clearable
                class="text-capitalize"
                :items="['raia', 'drogasil']"
                label="Bandeira"
                v-model="filtro.bandeira"
                @click:clear="filtraporbandeira"
                @change="filtraporbandeira(), filtrar()"
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="3"
              class="px-2"
              data-intro="Aqui você pode escolher a campanha que deseja"
            >

              <v-autocomplete
                ref="campanhas"
                outlined
                dense
                clearable
                v-model="filtro.id"
                :items="campanhas"
                item-text="nome"
                item-value="id"
                @change="FiltroPorCampanha()"
                label="Campanha"
                id="campanhas"
                class="campanha"
               
              >
                <!-- <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    {{ item.nome }}
                  </span>
                </template>
                <template v-slot:item="data">
                  <template v-if="data.item">
                    {{ data.item.nome }}
                  </template>
                </template> -->
              </v-autocomplete>
           
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="3"
              class="px-2"
              data-intro="As filias vão aparecer de acordo com a campanha que foi selecionada"
            >
              <v-autocomplete
                multiple
                outlined
                dense
                clearable
                prepend-inner-icon="mdi-magnify"
                v-model="filtro.filial"
                :items="filial"
                hide-no-data
                hide-details
                item-text="nome"
                item-value="id"
                @change="FiltroPorFilial()"
                label="Filial"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    {{ item.nome }}
                  </span>
                </template>
                <template v-slot:item="data">
                  <template v-if="data.item">
                    <optgroup :label="'ID JAVA: ' + data.item.id_java">
                      <option>
                        {{
                          data.item.nome !== null
                            ? data.item.nome
                            : "Loja ligada a campanha, mas sem nome"
                        }}
                      </option>
                    </optgroup>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
              <v-autocomplete
                multiple
                outlined
                dense
                clearable
                prepend-inner-icon="mdi-magnify"
                v-model="filtro.peca"
                :items="pecas"
                hide-no-data
                hide-details
                item-text="nome"
                item-value="id"
                label="Peça"
              >
                <!-- <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    {{ item.nome }}
                  </span>
                </template>
                <template v-slot:item="data">
                  <template v-if="data.item">
                    {{ data.item.nome }}
                  </template>
                </template> -->
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
              <v-autocomplete
                multiple
                outlined
                dense
                clearable
                prepend-inner-icon="mdi-magnify"
                v-model="filtro.justificativa"
                :items="justificativas"
                item-text="observacao"
                item-value="observacao"
                hide-no-data
                hide-details
                label="Justificativa"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row
            ><v-col align="end" justify="end">
              <v-btn
                data-intro="Selecionado os filtros que deseja vê, basta clicar em filtrar"
                class="ml-2 mb-1"
                color="primary"
                @click="filtrar()"
              >
                <v-icon left>mdi-filter</v-icon> Filtrar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>

    <v-container fluid id="element">
      <Highcharts :options="optionsPositivacao" class="elevantionChart" />
      <Highcharts :options="optionsJustificativa" class="elevantionChart" />
      <Highcharts :options="optionsCampanha" class="elevantionChart" />
      <Highcharts :options="optionsPeca" class="elevantionChart" />
      <Highcharts :options="optionsStatus" class="elevantionChart" />
      
      <v-card class="mt-2 py-4 px-2" elevation="10">
        <v-btn class="ml-2 my-1" color="primary" @click="exportar">
          <v-icon left>mdi-microsoft-excel</v-icon> Exportar
        </v-btn>
        <v-row align="center" justify="center">
          <v-col cols="11" class="px-2 mx-1">
            <!-- <Highcharts :options="optionsFilial" /> -->

            <h2 align="center" justify="center">TOP 10 FILIAL</h2>
            <v-data-table
              :headers="headersTop10"
              :items="dadostop10"
              :hide-default-footer="true"
              class="table"
            >
              <template v-slot:[`item.ranking`]="{ item }">
                <v-icon v-if="item.ranking == 1" color="warning"
                  >emoji_events</v-icon
                >
                <br />
                {{ item.ranking }}
              </template>

              <template v-slot:[`item.percentual`]="{ item }">
                {{ item.percentual }}%
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import moment from "moment";
import "intro.js/minified/introjs.min.css";
import introJS from "intro.js";
import XLSX from "xlsx";
import axios from "axios";
import Highcharts from "highcharts";
import loadMap from "highcharts/modules/map.js";
import { genComponent } from "vue-highcharts";

loadMap(Highcharts);

import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";
import loadExportData from "highcharts/modules/export-data";

stockInit(Highcharts);
exportingInit(Highcharts);
loadExportData(Highcharts);
Highcharts.setOptions({
  lang: {
    contextButtonTitle: "Menu",
    downloadJPEG: "Exportar JPG",
    downloadPDF: "Exportar PDF",
    downloadPNG: "Exportar PNG",
    downloadSVG: "Exportar SVG",
    viewFullscreen: "Tela Inteira",
    exitFullscreen: "Sair da Tela Inteira",
    printChart: "Imprimir",
    downloadXLS: "Exportar XLS",
    downloadCSV: "Exportar CSV",
    hideData: "",
    viewData: "",
    decimalPoint: ",",
  },
});

export default {
  components: {
    Highcharts: genComponent("Highcharts", Highcharts),
  },
  data: () => ({
    dataFiltrar: '',
    dataFiltrarFim: '',
    dataAnoMes: "",
    campanhas: [],
    campanhasObjOrinal: [],
    justificativas: [],
    pecas: [],
    filial: [],
    filtro: {},
    dadostop10: [],
    headersTop10: [
      { text: "RANKING", value: "ranking", align: "center" },
      { text: "ID JAVA", value: "id_java", align: "center" },
      { text: "FILIAL", value: "nome", align: "center" },
      { text: "CIDADE", value: "cidade", align: "center" },
      { text: "ESTADO", value: "estado", align: "center" },
      { text: "% POSITIVAÇÃO", value: "percentual", align: "center" },
      { text: "QTD POSITIVAÇÃO", value: "positivado", align: "center" },
    ],

    snackbar: false,
    snackbarText: "",
    overlay: true,

    campanhapositivacao: [],
    pecapositivacao: [],
    dadospositivacao: [],
    dadosjustificativa: [],

    optionsPositivacao: {
      chart: {
        type: "column",
      },
      exporting: {
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
              },
            },
          },
        },
        fallbackToExportServer: false,
      },

      title: {
        text: "Positivação x Mês",
      },
      subtitle: {
        text: "Positivação mês",
      },

      xAxis: {
        // categories: ["Positivação"],
        categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Quantidade",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y} </b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y}",
          },
        },
      },
      series: [],
    },
    optionsCampanha: {
      chart: {
        type: "pie",
      },
      exporting: {
        chartOptions: {
          // specific options for the exported image
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
              },
            },
          },
        },
        fallbackToExportServer: false,
      },
      title: {
        text: "Positivação x Campanha",
      },
      subtitle: {
        text: "Top 10 % de positivação",
      },
      xAxis: {
        categories: ["Positivação"],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Percentual",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          "<tr>" +
          '<td style="padding:0"><b>{point.y}%</b></td>' +
          '<td style="padding:0">SIM: {point.positivado} </td>' +
          '<td style="padding:0">NÃO: {point.Npositivado}</td>' +
          "</tr>",
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          showInLegend: true,
          dataLabels: {
            enabled: true,
            format: '{point.y}%'
          }
        }
      },
      series: [],
      legend: {
        enabled: true,
        labelFormatter: function() {
          let legendIndex = this.index;
          // var legendName = this.series.chart.axes[0].categories[legendIndex];
          let percentage = (this.series.data[legendIndex].percentage.toFixed(0)).replace('.', ',');
          let name = this.series.data[legendIndex].name;
          return `${name}: ${percentage}%`;
        }
      },
    },
    optionsPeca: {
      chart: {
        type: "pie",
      },
      exporting: {
        chartOptions: {
          // specific options for the exported image
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true
              },
            },
          },
        },
        fallbackToExportServer: false,
      },
      title: {
        text: "Positivação x Peça",
      },
      subtitle: {
        text: "Top 10 % de positivação",
      },
      xAxis: {
        categories: ["Nome", "positivado"],
        crosshair: true,
        title: {
          text: "Nome",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Percentual",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          "<tr>" +
          '<td style="padding:0"><b>{point.y}%</b></td>' +
          '<td style="padding:0">SIM: {point.positivado} </td>' +
          '<td style="padding:0">NÃO: {point.Npositivado}</td>' +
          "</tr>",
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          showInLegend: true,
          dataLabels: {
            enabled: true,
            format: '{point.y}%'
          }
        }
      },
      series: [],
      legend: {
        enabled: true,
        labelFormatter: function() {
          let legendIndex = this.index;
          // var legendName = this.series.chart.axes[0].categories[legendIndex];
          let percentage = (this.series.data[legendIndex].percentage.toFixed(0)).replace('.', ',');
          let name = this.series.data[legendIndex].name;
          return `${name}: ${percentage}%`;
        }
      },
    },

    optionsStatus: {
      chart: {
        type: "pie",
      },
      exporting: {
        chartOptions: {
          // specific options for the exported image
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
              },
            },
          },
        },
        fallbackToExportServer: false,
      },
      title: {
        text: "Filiais e Positivação x Status",
      },
      subtitle: {
        text: '',
      },
      accessibility: {
          announceNewData: {
              enabled: true
          },
          point: {
              valueSuffix: '%'
          }
      },
      plotOptions: {
          series: {
              dataLabels: {
                  enabled: true,
                  format: '{point.name}: {point.y:.2f}%'
              },
              showInLegend: true
          }
      },
      legend: {
        enabled: false,
        labelFormatter: function() {
          let legendIndex = this.index;
          // var legendName = this.series.chart.axes[0].categories[legendIndex];
          let percentage = (this.series.data[legendIndex].percentage.toFixed(2)).replace('.', ',');
          let name = this.series.data[legendIndex].name;
          return `${name}: ${percentage}%`;
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">Total Lojas</span><br>',
        pointFormat: `
          <span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}% ({point.qtd_por_status} lojas)</b><br/>
          <span style="color:{point.color}">Peças positivadas</span>: <b>{point.positivado}</b><br/>
          <span style="color:{point.color}">Peças não positivadas</span>: <b>{point.nao_positivado}</b><br/>
        `
      },
      series: [],
    },

    optionsJustificativa: {
      chart: {
        type: "column",
      },
      exporting: {
        chartOptions: {
          // specific options for the exported image
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
              },
            },
          },
        },
        fallbackToExportServer: false,
      },
      title: {
        text: "Justificativa",
      },
      subtitle: {
        text: "top 10",
      },
      xAxis: {
        
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Quantidade",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y} </b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y}",
          },
        },
      },
      series: [],
    },

    charts: {
      positivacao: [],
      positivacaoJustificativa: [],
      positivacaoStatus: []
    },
  }),
  computed: {
    campanhasPorId() {
      let campanhas = []
      this.campanhas.forEach((item) => {
        campanhas[item.id] = item.nome;
      });

      return campanhas;
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    exportar() {
      console.log("aoooooo");
      const data = this.dadostop10;
      const ws = XLSX.utils.json_to_sheet(data);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "TOP 10 FILIAL");
      XLSX.writeFile(wb, `top_10_filial${new Date().getTime()}.xlsx`);
    },

   async filtropordata() {
      this.campanhas = this.campanhasObjOrinal;

      if (this.dataFiltrar && this.dataFiltrarFim) {
       
        let datainicio = moment(this.dataFiltrar).format() ;
        let datafim = moment(this.dataFiltrarFim).format() ;

        console.log(datainicio, datafim);
    

        this.campanhas = await this.campanhas.filter((val) => {
          let data_ini = moment(val.data_ini).format();
          let data_fim = moment(val.data_fim).format();

       
          this.$refs['campanhas'].$el.classList.add('campanhas2')
          this.$refs['campanhas'].$el.classList.add('v-text-field--outlined::v-deep')
          this.$refs['campanhas'].$el.classList.add('fieldset')        

          return  data_ini >= datainicio  && datafim <= data_fim
        });

      } else {
        false;
      }
    },

    filtraporbandeira() {
      this.campanhas = this.campanhasObjOrinal;
  
      if (this.filtro.bandeira) {
          this.campanhas = this.campanhas.filter((val) => val.bandeira == this.filtro.bandeira)
      }
    },

    // =====================FILIAL========================

    async FiltroPorCampanha() {
      await axios
        .post(
          `${this.$SERVER_URL}campanha/campanhaloja`,
          this.filtro,

          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          }
        )
        .then((res) => {
          var filial = res.data;

          this.filial = filial.map((val) => val);
        });

      this.pecas = (
        await axios.get(`${this.$SERVER_URL}adm/allPecas`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
      ).data;
    },

    // =====================FILIAL========================
    // =======================PEÇAS=================================
    async FiltroPorFilial() {
      await axios
        .post(
          `${this.$SERVER_URL}campanha/campanhalojaPeca`,
          this.filtro,

          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          }
        )
        .then((res) => {
          var pecas = res.data;

          this.pecas = pecas.map((val) => val);
        });
    },
    async filtroJustificativas() {
      //   ===================JUSTIFICATIVAS====================

      await axios
        .get(`${this.$SERVER_URL}campanha/justificativas`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((res) => {
          var justificativas = res.data;

          this.justificativas = justificativas;
        });
      // =======================PEÇAS=================================
    },

    async filtrar() {
      
      const dados = {
        dataFiltrar: this.dataFiltrar ? this.dataFiltrar : null,
        dataFiltrarFim: this.dataFiltrarFim ? this.dataFiltrarFim : null,
        filter: this.filtro ? this.filtro : null,
        dataAnoMes: this.dataAnoMes ? this.dataAnoMes : null,
      };

      const res = (
        await axios
          .put(`${this.$SERVER_URL}adm/graficos`, dados, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          })
          .catch((err) => {
            this.dataFiltrar = this.$moment().startOf('month').format("YYYY-MM-DD");
            this.dataFiltrarFim = this.$moment().endOf('month').format("YYYY-MM-DD");
            this.filtro = "";
            this.dataAnoMes = "";
            alert(err.response.data.alerta);
          })
      ).data;

      this.optionsStatus.subtitle.text = this.campanhasPorId[this.filtro.id];
      this.optionsStatus = {
          ...this.optionsStatus,
          series : [{data: res.positivacaoStatus}]
      };
      
      this.optionsPositivacao = {
        ...this.optionsPositivacao, 
          xAxis: {
       
        categories: [...res.positivacao.map(v=>v.mes).sort(function (a, b) {
          return a - b;
        })],
        crosshair: true,
         },
     
        series: 
    
            [
          {
            name: "Positivado",
            data: [...res.positivacao.map(v=>Math.round(parseInt(v.positivado)))],
          },
          {
            name: "Não Positivado",
            data:[...res.positivacao.map(v=>Math.round(parseInt(v.NPositivado)))],
          },
          {
            name: "Todos",
            data: [...res.positivacao.map(v=>Math.round(parseInt(v.Todos)))],
          },
            ]
      };

      const allTop10 = res.top10filiais.reduce(
        (acc, cur) => acc + parseInt(cur["positivado"], 10),
        0
      );

      this.dadostop10 = res.top10filiais
        .map((v, index) => ({
          nome: v.nome,
          estado: v.estado,
          cidade: v.cidade,
          positivado: v.positivado,
          soma: v.soma,
          id_java: v.id_java,
          ranking: index + 1,
          percentual: parseFloat(
            (Number(v.positivado) * 100) / allTop10
          ).toFixed(2),
        }))
        .sort(function (a, b) {
          return b - a;
        });
        


      res.top10filiais = res.top10filiais.map((val) => ({
        id_java: val["id_java"],
        soma: parseInt(val["soma"], 10),
      }));

      if (!this.filtro.justificativa) {
        const allPositivacaoCampanha = res.positivacaoCampanha.reduce(
          (acc, cur) => acc + parseInt(cur["positivado"], 10),
          0
        );
        this.optionsCampanha = {
          ...this.optionsCampanha,
          series: [
            {
              data: res.positivacaoCampanha.map((val) => ({
                name: val["nome"],
                Npositivado: parseInt(val.naopositivado, 10),
                positivado: parseInt(val.positivado, 10),
                y: Math.round(
                  (parseInt(val.positivado, 10) * 100) / allPositivacaoCampanha
                ),
              })),
            },
          ],
        };
      } else {
        const allPositivacaoCampanha = res.positivacaoCampanha.reduce(
          (acc, cur) => acc + parseInt(cur["naopositivado"], 10),
          0
        );
        this.optionsCampanha = {
          ...this.optionsCampanha,
          series: [
            {
              data: res.positivacaoCampanha.map((val) => ({
                name: val["nome"],
                Npositivado: parseInt(val.naopositivado, 10),
                positivado: parseInt(val.positivado, 10),
                y: Math.round(
                  (parseInt(val.naopositivado, 10) * 100) /
                    allPositivacaoCampanha
                ),
              })),
            },
          ],
        };
      }

      if (!this.filtro.justificativa) {
        const alloptionsPeca = res.positivacaoPeca.reduce(
          (acc, cur) => acc + parseInt(cur["positivado"], 10),
          0
        );
        this.optionsPeca = {
          ...this.optionsPeca,

          
          series: [
            {
              data: res.positivacaoPeca.map((val) => ({
                positivado: val.positivado,
                Npositivado: val.naopositivado,
                name: val.nome,
                y: Math.round(
                  (parseInt(val.positivado, 10) * 100) / alloptionsPeca
                ),
              })),
            },
          ],
        };
      } else {
        const alloptionsPeca = res.positivacaoPeca.reduce(
          (acc, cur) => acc + parseInt(cur["naopositivado"], 10),
          0
        );

        this.optionsPeca = {
          ...this.optionsPeca,
          series: [
            {
              data: res.positivacaoPeca.map((val) => ({
                positivado: val.positivado,
                Npositivado: val.naopositivado,
                name: val.nome,
                y: Math.round(
                  (parseInt(val.naopositivado, 10) * 100) / alloptionsPeca
                ),
              })),
            },
          ],
        };
      }

      this.optionsJustificativa = {
        ...this.optionsJustificativa,
        
        series: res.positivacaoJustificativa.map((val) => ({
          name: val.observacao,
          data: [Math.round(Number(val.soma))],
        })),
      };

      this.charts = res;
      this.overlay = false;
    },
  },
  updated() {
    introJS().oncomplete(function () {
      localStorage.setItem("tutorial", "sim");
    });
    // add a flag when we exit
    introJS().onexit(function () {
      localStorage.setItem("tutorial", "sim");
    });
  },
  async mounted() {
    this.dataFiltrar = this.$moment().startOf('month').format("YYYY-MM-DD");
    this.dataFiltrarFim = this.$moment().endOf('month').format("YYYY-MM-DD");
    //introJS().start();

    //   ===================CAMPANHAS====================

    await axios
      .get(`${this.$SERVER_URL}adm/campanha`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      }).then((res) => {
        var campanhas = res.data;

        this.campanhas = campanhas.map((val) => val);
        this.campanhasObjOrinal = this.campanhas;
      });

    //   ===================CAMPANHAS====================

    //   ===================JUSTIFICATIVAS====================
    await this.filtrar();
    await this.FiltroPorCampanha();
    await this.FiltroPorFilial();
    await this.filtroJustificativas();
  },
};
</script>
<style lang="css" scoped  >

.elevantionChart {
    box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%) !important;
    border-radius: 4px;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.campanhas2.v-text-field--outlined::v-deep  fieldset   {
    margin: -5%px;
    border:3px solid black;    
    border-image: url('../assets/imagens/border.svg')1 ;
}
</style>
